import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

const BlogCard = ({BlogData}) => {
    
    const truncateString = (str, num) => {
        return num >= str.length ? str : str.slice(0, num)+'...'
    }

    return (
        <div className='row justify-content-center'>
            {BlogData?.map((blog, index) => 
                <div key={index} className="col-lg-6 col-md-6 col-sm-6 my-4" >
                    <div className="card">
                        <div className='card-body'>
                            <div className='image-title text-center'>
                                <h4>{blog?.node?.metaTitle}</h4>
                            </div>
                            <div className='blogImage mb-4' >
                                <img src={blog?.node?.cardImage?.url} alt={blog?.node?.cardImage?.alternativeText || "card Image"}/>
                            </div>

                            <div className='body-section'>
                                <h5 className="card-title mb-3">{blog?.node?.metaDescription}</h5>

                                {blog?.node?.contentBody?.map((body, index) => {
                                    return(
                                        body?.viewOnCard ?
                                    <div key={index} className='card-data'>
                                    <span className="card-subtitle mr-2"> {body?.head2} </span>
                                    <ReactMarkdown>{truncateString(body?.paragraph?.data?.paragraph, 200)}</ReactMarkdown>
                                    </ div> : ''
                                    )
                                })}
                            </div>

                            <div className='aboutBlog-info'>
                                <span className='d-flex justify-content-end'>
                                    <Link to={blog?.node?.slug} className='default-btn my-4'>
                                        <span></span>
                                        Continue Reading
                                        <i className='bx bx-chevron-right'></i>
                                    </Link>
                                </span>

                                <ul className='d-flex justify-content-between align-item-center p-0 mb-0'>
                                    <li className='author-link'>
                                        <Link to={blog?.node?.author?.slug}>
                                            <i className='bx bx-user-circle'></i> {blog?.node?.author?.authorName}
                                        </Link>
                                    </li>
                                    <li className='text-sm'>
                                        <i className='bx bx-calendar'></i> {blog?.node?.date}
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>        
    )
}

export default BlogCard
import React from 'react'
import Layout from '../components/App/Layout'
import Navbar from '../components/App/Navbar'
import Footer from '../components/App/Footer'
import BlogComponent from '../components/Blogs/BlogComponent'
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'


const Blogs = () => {
  return (
    <Layout>
      <Helmet>
        <meta name='robots' content='index, follow' />
        <link rel="canonical" href="https://zaavia.net/blogs/" />
        <meta name="description" content="From the newest technological innovations, essential health tips OR savvy business tactics, our blog provides the information you need to thrive in this Digital Age:)"></meta>
      </Helmet>
      <TagsForAll pageTitle="Your Ultimate Guide to Technology, Health, Business Trends & More!" />

      <Navbar />
      <BlogComponent />
      <Footer />
    </Layout>
  )
}

export default Blogs
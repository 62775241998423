import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import BlogCard from './BlogCard'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
{
  allStrapiBlog {
    edges {
      node {
        metaTitle
        metaDescription
        slug
        author{
          authorName
          slug
        }
        date
        cardImage{
          alternativeText
          url
        }
        contentBody{
          viewOnCard
          head2
          paragraph{
            data{
              paragraph
            }
          }
        }
      }
    }
  }
}
`
const BlogComponent = () => {
    const blog = useStaticQuery(query)
    const BlogData = blog?.allStrapiBlog?.edges?.sort((a, b) =>  (new Date(a?.node?.date)) - (new Date(b?.node?.date)))

    return (
        <section className="blogs-card pb-70">
            <div className="container">
                <div className='title-content'>
                    <h2 className='blog-heading'>
                        <img src={starIcon} alt="blog" />Blogs
                    </h2>
                </div>
                <div className="card-section">
                    <BlogCard BlogData={BlogData}  />
                </div>
            </div>
        </section>

    )
}

export default BlogComponent